/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.filter-page__footer {
  margin-top: 30px;
  padding: 20px;
}
.filter-page__footer h2,
.filter-page__footer h3 {
  font-family: Montserrat, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
}
.filter-page__footer h2 {
  font-size: 24px;
}
.filter-page__footer h3 {
  font-size: 20px;
}
.filter-page__footer p {
  font-family: Montserrat, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  /* stylelint-disable declaration-no-important, legacy */
  max-width: 100% !important;
  color: #404040 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: left !important;
  /* stylelint-enable declaration-no-important, legacy */
}
.filter-page__footer a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.filter-page__footer a:hover {
  color: #56B5A1;
  text-decoration: none;
}
.filter-page__footer a:active,
.filter-page__footer a:focus {
  color: #05857A;
  text-decoration: underline;
}
.filter-page__footer__link-section-title {
  font-family: Montserrat, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px !important;
  /* stylelint-disable-line declaration-no-important */
}
.filter-page__footer__link-list {
  padding: 0;
}
.filter-page__footer__link-list__item {
  font-size: 14px;
  line-height: 24px;
  list-style: none;
}
