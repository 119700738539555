/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.filter-page__service-toast {
  display: block;
}
.filter-page__service-toast__content {
  font-family: Montserrat, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 13px;
}
.filter-page__service-toast__content__header {
  display: flex;
}
.filter-page__service-toast__content__header__close {
  cursor: pointer;
}
.filter-page__service-toast__content__header__picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.filter-page__service-toast__content__header__title {
  flex-grow: 1;
  align-self: center;
  margin: 0 16px;
  color: #6f7f90;
}
.filter-page__service-toast__content__message {
  margin-top: 16px;
  color: #404040;
  font-weight: 500;
  line-height: 1.5;
}
